import { MAX_CHALLENGES } from '../../constants/settings'
import { CompletedRow } from './CompletedRow'
import { CurrentRow } from './CurrentRow'
//import { EmptyRow } from './EmptyRow'
import { GameMode } from '../../lib/modes'
import { VALID_GUESSES } from '../../constants/validGuesses'

type Props = {
  solution: string
  guesses: string[]
  currentGuess: string
  isRevealing?: boolean
  isDarkMode: boolean
  currentRowClassName: string
  hideStatus?: boolean
  gameMode: GameMode
}

export const Grid = ({
  solution,
  guesses,
  currentGuess,
  isRevealing,
  isDarkMode,
  currentRowClassName,
  hideStatus,
  gameMode,
}: Props) => {
  //const empties =
  //  guesses.length < MAX_CHALLENGES - 1
  //    ? Array.from(Array(MAX_CHALLENGES - 1 - guesses.length))
  //    : []
  const impossibleGame = gameMode === GameMode.Impossible
  const totalGuesses = impossibleGame ? VALID_GUESSES.length : MAX_CHALLENGES

  return (
    <>
      {guesses.map((guess, i) => (
        <CompletedRow
          key={i}
          solution={solution}
          guess={guess}
          isRevealing={isRevealing && guesses.length - 1 === i}
          isDarkMode={isDarkMode}
          hideStatus={hideStatus}
          gameMode={gameMode}
        />
      ))}
      {(guesses.length < MAX_CHALLENGES || impossibleGame) &&
        hideStatus && (
        <CurrentRow
          guess={currentGuess}
          className={currentRowClassName}
          gameMode={gameMode}
          isDarkMode={isDarkMode}
        />
      )}
      <div className='flex justify-center mb-1 dark:text-white text-black'>
        Guesses left: {totalGuesses - guesses.length}
      </div>
      {/*
        *{empties.map((_, i) => (
        *  <EmptyRow key={i} />
        *))}
        */}
    </>
  )
}
