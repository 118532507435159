import {
  //HARD_MODE_DESCRIPTION,
  HIGH_CONTRAST_MODE_DESCRIPTION,
} from '../../constants/strings'
import { BaseModal } from './BaseModal'
import { SettingsToggle } from './SettingsToggle'
import { SettingsGameMode } from './SettingsGameMode'
import { GameMode } from '../../lib/modes'

type VoidBooleanFun = (val: boolean) => void

type Props = {
  isOpen: boolean
  handleClose: () => void
  gameMode: GameMode
  handleHardMode: VoidBooleanFun
  handleGameMode: (mode: GameMode) => void
  isDarkMode: boolean
  handleDarkMode: VoidBooleanFun
  isHighContrastMode: boolean
  handleHighContrastMode: VoidBooleanFun
}

export const SettingsModal = ({
  isOpen,
  handleClose,
  gameMode,
  handleHardMode,
  handleGameMode,
  isDarkMode,
  handleDarkMode,
  isHighContrastMode,
  handleHighContrastMode,
}: Props) => {
  return (
    <BaseModal title="Settings" isOpen={isOpen} handleClose={handleClose}>
      <div className="mt-2 flex flex-col divide-y">
        <SettingsGameMode
          settingName="Difficulty Mode"
          gameMode={gameMode}
          handleGameMode={handleGameMode}
         />
        {
        //<SettingsToggle
        //  settingName="Hardest Mode"
        //  flag={gameMode === GameMode.Hardest}
        //  handleFlag={handleHardMode}
        //  description={HARD_MODE_DESCRIPTION}
        // />
        }
        <SettingsToggle
          settingName="Dark Mode"
          flag={isDarkMode}
          handleFlag={handleDarkMode}
        />
        <SettingsToggle
          settingName="High Contrast Mode"
          flag={isHighContrastMode}
          handleFlag={handleHighContrastMode}
          description={HIGH_CONTRAST_MODE_DESCRIPTION}
        />
      </div>
    </BaseModal>
  )
}
