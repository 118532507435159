import classnames from 'classnames'

import { GameMode } from '../../lib/modes'

type Props = {
  settingName: string
  gameMode: GameMode
  handleGameMode: (mode: GameMode) => void
}

export const SettingsGameMode = ({
  settingName,
  gameMode,
  handleGameMode,
}: Props) => {
  const buttonClassName = "inline-block bg-gray-300 px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal text-black transition duration-150 ease-in-out hover:bg-gray-600 focus:bg-gray-600 focus:outline-none focus:ring-0 active:bg-gray-700"
  const harderButton = classnames(buttonClassName, "rounded-l",
    { 'bg-green-400': gameMode === GameMode.Harder, })
  const hardestButton = classnames(buttonClassName,
    { 'bg-green-400': gameMode === GameMode.Hardest, })
  const impossibleButton = classnames(buttonClassName, "rounded-r",
    { 'bg-green-400': gameMode === GameMode.Impossible, })

  return (
    <div className="space-y-3 mb-4">
      <div className="mt-2 text-left text-gray-500 dark:text-gray-300">
        <p className="leading-none">{settingName}</p>
      </div>

      <div className="items-center">
        <div className="flex inline-flex">
          <button type="button" className={harderButton}
            onClick={() => gameMode !== GameMode.Harder && handleGameMode(GameMode.Harder)}>
            Hard
          </button>
          <button type="button" className={hardestButton}
            onClick={() => gameMode !== GameMode.Hardest && handleGameMode(GameMode.Hardest)}>
            Hardest
          </button>
          <button type="button" className={impossibleButton}
            onClick={() => gameMode !== GameMode.Impossible && handleGameMode(GameMode.Impossible)}>
            Impossible
          </button>
        </div>
      </div>
    </div>
  )
}
