import { getGuessStatuses } from '../../lib/statuses'
import { unicodeSplit } from '../../lib/words'
import { Cell } from './Cell'
import { Indicator } from './Indicator'
import { GameMode } from '../../lib/modes'

type Props = {
  solution: string
  guess: string
  isRevealing?: boolean
  isDarkMode: boolean
  hideStatus?: boolean
  gameMode: GameMode
}

export const CompletedRow = ({
  solution,
  guess,
  isRevealing,
  isDarkMode,
  hideStatus,
  gameMode,
}: Props) => {
  const statuses = getGuessStatuses(solution, guess)
  const splitGuess = unicodeSplit(guess)

  const correct = statuses.reduce(
    (total, status_) => (total + Number(status_ === 'correct')), 0)
  const present = statuses.reduce(
    (total, status_) => (total + Number(status_ === 'present')), 0)

  let hint = <></>
  switch (gameMode) {
    case GameMode.Harder:
      hint = <>
          <Indicator status='correct' value={String(correct)} isCompleted isDarkMode={isDarkMode}/>
          <Indicator status='present' value={String(present)} isCompleted last={true} isDarkMode={isDarkMode}/>
        </>
      break;
    case GameMode.Hardest:
      hint = <Indicator status='either' value={String(correct + present)} isCompleted isDarkMode={isDarkMode}/>
      break;
  }

  return (
    <div className="mb-1 flex justify-center">
      {splitGuess.map((letter, i) => (
        <Cell
          key={i}
          value={letter}
          status={statuses[i]}
          position={i}
          isRevealing={isRevealing}
          isCompleted
          hideStatus={hideStatus}
        />
      ))}
      {hint}
    </div>
  )
}
