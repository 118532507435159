import classnames from 'classnames'

import { getStoredIsHighContrastMode } from '../../lib/localStorage'
import { CharStatus } from '../../lib/statuses'

type Props = {
  value?: string
  status?: CharStatus
  isCompleted?: boolean
  isDarkMode: boolean
  last?: boolean
}

export const Indicator = ({
  value,
  status,
  isCompleted,
  isDarkMode,
  last,
}: Props) => {
  const isHighContrast = getStoredIsHighContrastMode()

  const classes = classnames(
    'text-white xxshort:w-9 xxshort:h-11 short:text-2xl short:w-10 short:h-12 w-10 h-14 border-solid border flex items-center justify-center text-4xl font-bold dark:text-white',
    {
      'bg-orange-300 border-orange-300 dark:bg-orange-900 dark:border-orange-900':
        !isCompleted && status === 'correct' && isHighContrast,
      'bg-cyan-300 border-cyan-300 dark:bg-cyan-900 dark:border-cyan-900':
        !isCompleted && status === 'present' && isHighContrast,
      'bg-green-300 border-green-300 dark:bg-green-900 dark:border-green-900':
        !isCompleted && status === 'correct' && !isHighContrast,
      'bg-yellow-300 border-yellow-300 dark:bg-yellow-900 dark:border-yellow-900':
        !isCompleted && status === 'present' && !isHighContrast,
      'bg-orange-500 border-orange-500': isCompleted && status === 'correct' && isHighContrast,
      'bg-cyan-500 border-cyan-500':     isCompleted && status === 'present' && isHighContrast,
      'bg-green-500 border-green-500':   isCompleted && status === 'correct' && !isHighContrast,
      'bg-yellow-500 border-yellow-500': isCompleted && status === 'present' && !isHighContrast,
      'bg-slate-300 border-slate-300 dark:bg-slate-900 dark:border-slate-900':
        status === 'absent',
      'slanted-hint-bg-500':      status === 'either' && !isDarkMode && isCompleted && !isHighContrast,
      'slanted-hint-bg-500-dark': status === 'either' && isDarkMode && isCompleted && !isHighContrast,
      'slanted-hint-bg-300': status === 'either' && !isDarkMode && !isCompleted && !isHighContrast,
      'slanted-hint-bg-900': status === 'either' && isDarkMode && !isCompleted && !isHighContrast,
      'slanted-hint-bg-hc-500':      status === 'either' && !isDarkMode && isCompleted && isHighContrast,
      'slanted-hint-bg-hc-500-dark': status === 'either' && isDarkMode && isCompleted && isHighContrast,
      'slanted-hint-bg-hc-300': status === 'either' && !isDarkMode && !isCompleted && isHighContrast,
      'slanted-hint-bg-hc-900': status === 'either' && isDarkMode && !isCompleted && isHighContrast,
      'mx-3': !last,
      'mx-0': last,
    })

  return (
    <div className={classes}>
      {value}
    </div>
  )
}
