import { solution, unicodeSplit } from '../../lib/words'
import { Cell } from './Cell'
import { Indicator } from './Indicator'
import { GameMode } from '../../lib/modes'

type Props = {
  guess: string
  className: string
  gameMode: GameMode
  isDarkMode: boolean
}

export const CurrentRow = ({
  guess,
  className,
  gameMode,
  isDarkMode,
}: Props) => {
  const splitGuess = unicodeSplit(guess)
  const emptyCells = Array.from(Array(solution.length - splitGuess.length))
  const classes = `flex justify-center mb-1 ${className}`

  let hint = <></>
  switch (gameMode) {
    case GameMode.Harder:
      hint = <>
          <Indicator status='correct' isDarkMode={isDarkMode}/>
          <Indicator status='present' last={true} isDarkMode={isDarkMode}/>
        </>
      break;
    case GameMode.Hardest:
      hint = <Indicator status='either' isDarkMode={isDarkMode}/>
      break;
  }

  return (
    <div className={classes}>
      {splitGuess.map((letter, i) => (
        <Cell key={i} value={letter} />
      ))}
      {emptyCells.map((_, i) => (
        <Cell key={i} />
      ))}
      {hint}
    </div>
  )
}
