export enum GameMode {
  Harder = 1,
  Hardest,
  Impossible
}

export const strToGameMode = (str: string | null) => {
  switch (str) {
    case 'harder':
      return GameMode.Harder
    case 'hardest':
      return GameMode.Hardest
    case 'impossible':
      return GameMode.Impossible
    default:
      return GameMode.Harder
  }
}

export const gameModeToStr = (mode: GameMode) => {
  switch (mode) {
    case GameMode.Harder:
      return 'harder'
    case GameMode.Hardest:
      return 'hardest'
    case GameMode.Impossible:
      return 'impossible'
    default:
      return 'harder'
  }
}
