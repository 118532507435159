import { Cell } from '../grid/Cell'
import { Indicator } from '../grid/Indicator'
import { BaseModal } from './BaseModal'
import { GameMode } from '../../lib/modes'
import { VALID_GUESSES } from '../../constants/validGuesses'

type Props = {
  isOpen: boolean
  handleClose: () => void
  gameMode: GameMode
  isDarkMode: boolean
}

export const InfoModal = ({
  isOpen,
  handleClose,
  gameMode,
  isDarkMode,
}: Props) => {
  const weary_row = 
    <>
      <Cell value="W" isCompleted={true} hideStatus={true} status="present" />
      <Cell value="E" isCompleted={true} hideStatus={true} status="present" />
      <Cell value="A" isCompleted={true} hideStatus={true} status="present" />
      <Cell value="R" isCompleted={true} hideStatus={true} status="present" />
      <Cell value="Y" isCompleted={true} hideStatus={true} status="present" />
    </>
  const pilot_row = 
    <>
      <Cell value="P" isCompleted={true} hideStatus={true} status="present" />
      <Cell value="I" isCompleted={true} hideStatus={true} status="present" />
      <Cell value="L" isCompleted={true} hideStatus={true} status="present" />
      <Cell value="O" isCompleted={true} hideStatus={true} status="present" />
      <Cell value="T" isCompleted={true} hideStatus={true} status="present" />
    </>
  const foxes_row = 
    <>
      <Cell value="F" isCompleted={true} hideStatus={true} status="present" />
      <Cell value="O" isCompleted={true} hideStatus={true} status="present" />
      <Cell value="X" isCompleted={true} hideStatus={true} status="present" />
      <Cell value="E" isCompleted={true} hideStatus={true} status="present" />
      <Cell value="S" isCompleted={true} hideStatus={true} status="present" />
    </>

  const footer = 
    <>
      <p className="mt-6 text-sm italic text-gray-500 dark:text-gray-300">
        This is variant of the word guessing game we all know and
        love. It's
        {' '}<a
          href="*REDACTED*"
          className="font-bold underline"
        >
          open source
        </a>{' '}
        and
        {' '}<a
          href="*REDACTED*"
          className="font-bold underline"
        >
          derived from *REDACTED*
        </a>{' '}
      </p>
    </>

  switch (gameMode) {
  case GameMode.Harder:
    return (
      <BaseModal title="How to play" isOpen={isOpen} handleClose={handleClose}>
        <p className="text-sm text-gray-500 dark:text-gray-300">
          Guess the word in 14 attempts. After each guess, you'll receive two hints
          on the hidden word.
        </p>

        <div className="mb-1 mt-4 flex justify-center">
          {weary_row}
          <Indicator value="1" status='correct' isCompleted isDarkMode={isDarkMode}/>
          <Indicator value="0" status='present' isCompleted last isDarkMode={isDarkMode}/>
        </div>
        <p className="text-sm text-gray-500 dark:text-gray-300">
          A letter is in its correct spot, all others do not belong.
          That means, the hidden word has a W in the first position
          (and no E, A, R and Y), or an E in the 2nd, or A in 3rd,
          or R in 4th, or Y in 5th.
        </p>

        <div className="mb-1 mt-4 flex justify-center">
          {pilot_row}
          <Indicator value="0" status='correct' isCompleted isDarkMode={isDarkMode}/>
          <Indicator value="2" status='present' isCompleted last isDarkMode={isDarkMode}/>
        </div>
        <p className="text-sm text-gray-500 dark:text-gray-300">
          Two letters belong to the target word but they are not in their
          correct position. There are 10 possible pairs that might belong
          to the hidden word (2 combined 5), namely: PI, PL, PO, PT, IL,
          IO, IT, LO, LT and OT.
        </p>

        <div className="mb-1 mt-4 flex justify-center">
          {foxes_row}
          <Indicator value="1" status='correct' isCompleted isDarkMode={isDarkMode}/>
          <Indicator value="3" status='present' isCompleted last isDarkMode={isDarkMode}/>
        </div>
        <p className="text-sm text-gray-500 dark:text-gray-300">
          One letter is in its correct position, three other letters
          belong but are not in their correct positions, and one
          letter does not belong.
        </p>

        {footer}
      </BaseModal>
    )
    case GameMode.Hardest:
    return (
      <BaseModal title="How to play" isOpen={isOpen} handleClose={handleClose}>
        <p className="text-sm text-gray-500 dark:text-gray-300">
          Guess the word in 14 attempts. After each guess, you'll receive ONE hint
          on the hidden word.
        </p>

        <div className="mb-1 mt-4 flex justify-center">
          {weary_row}
          <Indicator value="1" status='either' isCompleted isDarkMode={isDarkMode}/>
        </div>
        <p className="text-sm text-gray-500 dark:text-gray-300">
          The hidden word has one of the letters: W, E, A, R, or Y, but not the others.
        </p>

        <div className="mb-1 mt-4 flex justify-center">
          {pilot_row}
          <Indicator value="2" status='either' isCompleted isDarkMode={isDarkMode}/>
        </div>
        <p className="text-sm text-gray-500 dark:text-gray-300">
          Two letters belong to the target hidden word. There are 10 possible pairs that
          might belong to the hidden word (2 combined 5), namely: PI, PL, PO, PT, IL,
          IO, IT, LO, LT and OT.
        </p>

        <div className="mb-1 mt-4 flex justify-center">
          {foxes_row}
          <Indicator value="4" status='either' isCompleted isDarkMode={isDarkMode}/>
        </div>
        <p className="text-sm text-gray-500 dark:text-gray-300">
          One letter does not belong to the target word, all the others do.
        </p>

        {footer}
      </BaseModal>
    )
    case GameMode.Impossible:
    return (
      <BaseModal title="How to play" isOpen={isOpen} handleClose={handleClose}>
        <p className="text-sm text-gray-500 dark:text-gray-300">
          Guess the word in {VALID_GUESSES.length} attempts. After each guess, you'll receive NO hint.
          If you find the word, you will know it.
        </p>

        {footer}
      </BaseModal>
    )
  }
}
